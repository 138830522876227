import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { AvatarTeam, Card, Subhead, Text } from 'hudl-uniform-ui-components';
import cardPreviewData from '../../../data/previews/card.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 'basic',
      selectedElement: 'body',
    };
  }

  handleTypeChange(value) {
    this.setState({
      selectedType: value,
    });
  }

  handleElementChange(value) {
    this.setState({
      selectedElement: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Card"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Card" />

        <PageNavigation>
          <PageNavigationLink>Cases</PageNavigationLink>
          <PageNavigationLink>Elements</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Cases">
          <Paragraph>
            While all cards are displayed in a familiar format, the content
            within them changes the purpose of each.
          </Paragraph>
          <ComponentPreview
            name="CardType"
            layout="default"
            previewData={cardPreviewData.cases}
            onChange={this.handleTypeChange.bind(this)}>
            {this.state.selectedType === 'basic' && (
              <div style={{ width: '361px' }}>
                <Card
                  header={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <AvatarTeam
                        sport="othersport"
                        size="large"
                        primaryColor="ff6500"
                        secondaryColor="ffffff"
                        className="uni-margin--half--right"
                      />
                      <div>
                        <Subhead>Hudl Originals</Subhead>
                        <Text color="subtle">Mar 4</Text>
                      </div>
                    </div>
                  }
                  footer={[
                    {
                      type: 'secondary',
                      label: 'React',
                      icon: 'add',
                      onClick: () => {},
                    },
                    {
                      type: 'secondary',
                      label: 'Share',
                      icon: 'share',
                      onClick: () => {},
                    },
                  ]}>
                  <Text>
                    Kalynn Meyer is using her platform to show the hard-work and
                    heart in small town Nebraska.
                  </Text>
                </Card>
              </div>
            )}
            {this.state.selectedType === 'media' && (
              <div style={{ width: '361px' }}>
                <Card
                  media={
                    <img src="https://va.hudl.com/perfcenter/10bc9651-a478-460e-bba8-c71ce9a446a8.480.jpg" />
                  }>
                  <Subhead className="uni-margin--one--top uni-margin--half--btm">
                    Hudl Originals
                  </Subhead>
                  <Text className="uni-margin--one--btm">
                    The only thing that matters is that you outwork every single
                    person.
                  </Text>
                  <Text level="small" color="nonessential">
                    Jan 26, 2019
                  </Text>
                </Card>
              </div>
            )}
          </ComponentPreview>
        </Section>

        <Section title="Elements">
          <Paragraph>
            Every card consists of a body, but can also include a header, footer
            and sometimes media content.
          </Paragraph>
          <ComponentPreview
            name="SpinnerState"
            layout="row"
            previewData={cardPreviewData.elements}
            onChange={this.handleElementChange.bind(this)}>
            <div style={{ width: '361px' }}>
              <Card
                media={
                  this.state.selectedElement === 'thumbnail' ||
                  this.state.selectedElement === 'complete' ? (
                    <div className="uni-margin--one--btm">
                      <img src="https://va.hudl.com/perfcenter/10bc9651-a478-460e-bba8-c71ce9a446a8.480.jpg" />
                    </div>
                  ) : null
                }
                header={
                  this.state.selectedElement === 'header' ||
                  this.state.selectedElement === 'complete' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                      }}>
                      <AvatarTeam
                        sport="othersport"
                        size="large"
                        primaryColor="ff6500"
                        secondaryColor="ffffff"
                        className="uni-margin--half--right"
                      />
                      <div>
                        <Subhead>Hudl Originals</Subhead>
                        <Text color="subtle">Mar 4</Text>
                      </div>
                    </div>
                  ) : null
                }
                footer={
                  this.state.selectedElement === 'footer' ||
                  this.state.selectedElement === 'complete'
                    ? [
                        {
                          type: 'secondary',
                          label: 'React',
                          icon: 'add',
                          onClick: () => {},
                        },
                        {
                          type: 'secondary',
                          label: 'Share',
                          icon: 'share',
                          onClick: () => {},
                        },
                      ]
                    : null
                }>
                {this.state.selectedElement !== 'thumbnail' &&
                  this.state.selectedElement !== 'complete' && (
                    <Subhead className="uni-margin--half--btm">
                      Chase Winovich — Draft 2019
                    </Subhead>
                  )}
                <Text>
                  The only thing that matters is that you outwork every single
                  person.
                </Text>
              </Card>
            </div>
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Content</SectionSubhead>
          <Paragraph>
            Content shows up in different ways when grouped into cards. Some
            require headers, some will only need footers and some might need
            both. When collectively displayed, they each should remain
            consistent and use the right card type.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="recreate a card type out of individual elements."
              img="card-recreate-dont"
            />
            <DontDoItem
              type="do"
              text="choose the right card for the content."
              img="card-recreate-do"
            />
          </DontDo>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="organize content in different ways when grouped together."
              img="card-organize-dont"
            />
            <DontDoItem
              type="do"
              text="keep cards consistent for easy scanning."
              img="card-organize-do"
            />
          </DontDo>

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Cards may vary in size depending on the purpose and content. Actions
            should only ever be present in the footer. Reserve these for the
            three most important.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overload the footer with actions."
              img="card-actions-dont"
            />
            <DontDoItem
              type="do"
              text="list the most important the user can take."
              img="card-actions-do"
            />
          </DontDo>

          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            In some cases cards may be selectable. If it makes sense to include
            selection, use our{' '}
            <Link href="/components/select-mark/design">select mark</Link> in
            the upper left corner.
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Titles should be concise and briefly explain the content. Write them
            in title case, but don’t worry about punctuation for these.
          </Paragraph>
          <SectionSubhead>Date and Time</SectionSubhead>
          <Paragraph>
            Cards are nearly synonymous with time as they’re most often
            displayed in feeds. Follow our{' '}
            <Link href="/words/content-elements/date-time">date and time</Link>{' '}
            guidelines.
          </Paragraph>
          <SectionSubhead>Case &amp; Punctuation</SectionSubhead>
          <Paragraph>
            Like most things, cards follow our{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              non-label text guidelines
            </Link>{' '}
            and use appropriate{' '}
            <Link href="/words/writing-guidelines/punctuation">
              punctuation
            </Link>{' '}
            and sentence case.
          </Paragraph>
        </Section>

        <Section title="Platform">
          <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
